.rh-pagination .page-item {
    --bs-pagination-color: #198754;
    --bs-pagination-hover-color: #125a38;
    --bs-pagination-focus-color: #125a38;
    --bs-pagination-active-bg: #198754;
    --bs-pagination-active-border-color: #198754;
}

/* .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: rgb(18 153 36 / 5%) !important;
} */